<template>
  <div class="insurance increment-wrap">
    <div class="line" />
    <h2>保险服务</h2>
    <div class="img">
      <!-- <div class="title">保险服务</div> -->
      <p class="text">
        对货投保 提供物流运输险、物流责任险<br>
        对人投保 提供员工意外险、雇主责任险<br>
        对车投保 提供车险
      </p>
      <div style=" text-align:center;"><img src="./../../assets/img/9.jpg" alt=""></div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
@import "./../../assets/scss/base.scss";

</style>
